import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { Interaction } from '@/app/store/modules/conversation/conversation.models';
import * as moment from 'moment';
import ResponseMessage from '../message-response/MessageResponse.vue';
import WaveSurfer from 'wavesurfer.js';

@Component({
  name: 'message-audio',
  components: { ResponseMessage },
})
export default class AudioType extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;
  @Prop() readonly project!: any;

  public fileUrl = '';
  public waveSurferPlayer: any = null;
  public isPlayingAudio: boolean = false;
  public time: string = '0:00';
  public userPlaybackSpeed: number = 1;
  public realPlaybackSpeed: number = 1;

  mounted(): void {
    this.dataUrlToFile();
    this.generateWaveSurf();
  }

  get CreatedAt() {
    return moment.default(this.message.createdAt).format('LT');
  }

  get Mode() {
    return this.message.mode === 'OUTPUT';
  }

  dataUrlToFile(): void {
    let fileurl: any = this.message.fileurl;
    this.fileUrl = fileurl;
  }

  generateWaveSurf(): void {
    const surfer = <HTMLElement>this.$refs.playerWaveform;

    this.waveSurferPlayer = WaveSurfer.create({
      container: surfer,
      height: 32,
      waveColor: '#777777',
      progressColor: '#009ee3',
      cursorColor: '#009ee3',
      cursorWidth: 0,
      barMinHeight: 1,
      barWidth: 3,
      barGap: 3,
      barRadius: 3,
    });
    this.waveSurferPlayer.load(this.fileUrl);
    this.waveSurferPlayer.on('ready', this.totalTime);
    this.waveSurferPlayer.on('audioprocess', this.updateTimer);
    this.waveSurferPlayer.on('seek', this.updateTimer);
    this.waveSurferPlayer.on('finish', this.finishAudio);
  }

  playAudio(): void {
    this.isPlayingAudio = true;
    this.waveSurferPlayer.playPause();
    this.waveSurferPlayer.setPlaybackRate(this.realPlaybackSpeed);
  }

  pauseAudio(): void {
    this.isPlayingAudio = false;
    this.waveSurferPlayer.playPause();
    this.totalTime();
  }

  changeAudioSpeed(): void {
    this.userPlaybackSpeed = this.userPlaybackSpeed + 0.5;
    if (this.userPlaybackSpeed === 1) {
      this.realPlaybackSpeed = 1;
    } else if (this.userPlaybackSpeed === 1.5) {
      this.realPlaybackSpeed = 1.15;
    } else if (this.userPlaybackSpeed === 2) {
      this.realPlaybackSpeed = 1.3;
    } else if (this.userPlaybackSpeed > 2) {
      this.userPlaybackSpeed = 1;
      this.realPlaybackSpeed = 1;
    }
    this.waveSurferPlayer.setPlaybackRate(this.realPlaybackSpeed);
  }

  finishAudio(): void {
    this.isPlayingAudio = false;
    this.waveSurferPlayer.stop();
    this.totalTime();
  }

  totalTime(): void {
    let formattedTime = this.secondsToTimestamp(
      this.waveSurferPlayer.getDuration(),
    );
    this.time = formattedTime;
  }

  updateTimer(): void {
    let formattedTime = this.secondsToTimestamp(
      this.waveSurferPlayer.getCurrentTime(),
    );
    this.time = formattedTime;
  }

  secondsToTimestamp(seconds: number): string {
    seconds = Math.floor(seconds);
    let min = Math.floor(seconds / 60);
    let sec = seconds - min * 60;
    let m;
    let s;

    m = min < 10 ? '0' + min : min;
    s = sec < 10 ? '0' + sec : sec;
    return m + ':' + s;
  }
}
