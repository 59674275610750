import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import {
  ConversationNewEntryQueue,
  Conversation,
  Interaction,
  MessageType,
  InteractionOrigin,
  BusinessStatus,
} from '@/app/store/modules/conversation/conversation.models';
import { VueWizard } from '@/vue-wizard';
import PulseLoader from 'vue-spinner/src/PulseLoader.vue';
import ConversationMessage from '../conversationMessage/ConversationMessage.vue';
import CustomButton from '../actions/custom-button.vue';
import { store, storeTypes } from '@/app/store';
import moment from 'moment';
import { PerfectScrollbar } from 'vue2-perfect-scrollbar';
import eventBus from '@/main';
import axios from 'axios';
import { environment } from '@/environments/environment';
import { conversationActionsTypes } from '@/app/store/modules/conversation/conversation.actions';
import { set } from 'vue/types/umd';
@Component({
  name: 'conversation-content',
  components: {
    PulseLoader,
    ConversationMessage,
    PerfectScrollbar,
    CustomButton,
  },
})
export default class ConversationContent extends VueWizard {
  @Prop() readonly currentConversation!: Conversation;
  @Prop() readonly currentCloseConversations!: Conversation[];
  @Prop() readonly isClosed!: boolean;
  @Prop() readonly EventBus!: Vue;
  @Prop() readonly profilePicture!: string;
  @Prop() public businessStatuses!: BusinessStatus[];
  @Prop() public project!: any;

  public newMessage = false;
  public maxHeightSubstract = 0;
  public sendMessage: string[] = [];
  public loading: boolean = false;
  public replyText: string = '';
  public conversationReply: string[] | any = '';
  public emptyText: object = { emptyText: 'Sin conversaciones' };
  public visible: boolean = false;
  public interact: any[] = [];
  public selected_consumers: any[] = [];
  public refData: any = '';
  public showMarkAsRead: boolean = false;
  public showResolveConversationModal = false;
  // get actual tab selected

  // solo se debe mostrar el boton de cerrar conversacion si la conversacion esta en estado IN_QUEUE y el
  // el tab seleccionado es la cola de entrada
  public get currentTab() {
    const tab = this.$store.state.agent.tabSelected;

    const statusCurrentConversation = this.currentConversation.status;
    if (
      ((statusCurrentConversation === 'IN_QUEUE' ||
        statusCurrentConversation === 'NEW' ||
        statusCurrentConversation === 'NEW_AUTO' ||
        statusCurrentConversation === 'AUTO') &&
        tab === 'encola') ||
      tab === 'auto'
    ) {
      return true;
    } else {
      return false;
    }
  }

  public get currentTabClose() {
    const tab = this.$store.state.agent.tabSelected;
    const statusCurrentConversation = this.currentConversation.status;
    if (statusCurrentConversation === 'RESOLVED' && tab === 'resueltas') {
      return true;
    } else {
      return false;
    }
  }

  statusColorStyle(status: BusinessStatus): any {
    if (status) {
      return {
        '--label-bg-color-r': this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).r
          : 0,
        '--label-bg-color-g': this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).g
          : 0,
        '--label-bg-color-b': this.hexToRgb(status.color)
          ? (this.hexToRgb(status.color) as any).b
          : 0,
      };
    } else {
      return {};
    }
  }

  hexToRgb(hex: string): any {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  getBusinessStatus(status: string): BusinessStatus {
    const element = this.businessStatuses.find(
      (businessStatus: BusinessStatus) => businessStatus.name === status,
    );
    if (element) {
      return element;
    } else {
      // return businessStatuses default
      return this.businessStatuses[0];
    }
  }

  formattedDate(date: Date): string {
    return moment(date).format('LT');
  }

  send_share_message(selected_consumers: any[], currentSendMessage: any) {
    this.loading = true;
    const response = selected_consumers.map((consumer: any) => {
      return store.dispatch(
        storeTypes.conversation.actions.shareSendMessage({
          id: consumer._id,
          type: currentSendMessage.type,
          text: currentSendMessage.text,
          referenceId: currentSendMessage._id,
          filename: currentSendMessage.filename,
          fileurl: currentSendMessage.fileurl,
          mimetype: currentSendMessage.mimetype,
          origin: InteractionOrigin.FRW,
        }),
      );
    });
    Promise.all(response)
      .then(() => {
        this.visible = false;
      })
      .finally(() => {
        this.loading = false;
      });
  }

  get totalPages(): number {
    return store.state.agent!.totalPages;
  }

  get actualPage(): number {
    return store.state.agent!.actualPage;
  }

  get agentOpenConversations(): any {
    return store.state.agent!.agentOpenConversations;
  }

  created(): void {
    this.EventBus.$on('showAttachmentSite', this.onShowAttachmentSite);
    this.EventBus.$on('sendingMessage', this.onSendingMessage);
    this.EventBus.$on('sentMessage', this.onSentMessage);
    eventBus.$on(
      'showConversationContentLoading',
      this.onShowConversationContentLoading,
    );
  }

  get interactions(): Interaction[] {
    let interactions = store.state.agent!.interactions;
    interactions.map((interaction: Interaction) => {
      if (!interaction.type) {
        interaction.type = 'MESSAGE';
      }
    });
    return store.state.agent!.interactions;
  }

  async mounted(): Promise<any> {
    await this.chargeInteractions(this.actualPage);
  }
  async beforeMount(): Promise<any> {
    await this.chargeInteractions(this.actualPage);
  }
  @Watch('currentConversation')
  async changeInteractions(): Promise<any> {
    store.commit(storeTypes.agent.mutations.setInteractions([]));
    store.commit(storeTypes.agent.mutations.setActualPage(0));
    store.commit(storeTypes.agent.mutations.setTotalPages(0));
    await this.chargeInteractions(this.actualPage);
  }

  async chargeInteractions(page: number): Promise<any> {
    this.loading = true;
    await this.loadInteractions(page);
    this.loading = false;
    this.maxHeightSubstract = this.currentConversation.isClosed ? 0 : 160;
    this.scrollBottom();
  }

  scrollBottom(): void {
    const scroll = document.getElementById('chat-content-agent') as HTMLElement;
    if (scroll) {
      scroll.scrollTop = scroll.scrollHeight;
    }
  }

  beforeDestroy(): void {
    // store.commit(storeTypes.agent.mutations.setInteractions([]));
    store.commit(storeTypes.agent.mutations.setActualPage(0));
    store.commit(storeTypes.agent.mutations.setTotalPages(0));
  }

  /**
   * este metodo es el que procesa el boton cargar mas...
   */
  async loadMoreInteractions(): Promise<any> {
    this.loading = true;
    let page = this.actualPage;
    await this.loadInteractions(++page);
    this.loading = false;
  }

  async loadInteractions(page: number): Promise<any> {
    await store.dispatch(
      storeTypes.agent.actions.loadActualInteractions({
        conversationId: this.currentConversation._id,
        page,
      }),
    );
  }
  getTopic(item: ConversationNewEntryQueue): string {
    let topic = item.topic || item.currentTopic || '';
    if (topic.includes('_')) {
      topic = topic.split('_')[1];
    }
    return topic;
  }

  getUserPicture(item: any): any {
    let profileurl = null;

    let conversation: Conversation;
    if (item.variables) {
      conversation = item;
    } else {
      conversation = item.conversation;
    }

    if (
      conversation != undefined &&
      conversation.variables.CONSUMER_PROFILE_PICTURE
    ) {
      profileurl = conversation.variables.CONSUMER_PROFILE_PICTURE.value;
    }
    return profileurl;
  }

  getUserName(item: any): string {
    if (item && item.variables && item.variables.CONSUMER_NAME) {
      return item.variables.CONSUMER_NAME.value || '';
    }
    return '';
  }

  formatUpdatedDate(date: any): string {
    moment.locale('es');
    return moment(date).calendar();
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }

  onShowAttachmentSite(size: number): void {
    this.maxHeightSubstract = size + 160;
    this.$nextTick(() => {
      this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight;
    });
  }

  onSendingMessage(id: string): void {
    this.sendMessage = this.sendMessage.concat(id);
    this.$nextTick(() => {
      this.$el.scrollTop = this.$el.scrollHeight - this.$el.clientHeight;
    });
  }

  onSentMessage(id: string): void {
    this.sendMessage = this.sendMessage.filter(el => el !== id);
  }

  /**
   * escucha el evento ShowConversationContentLoading
   */
  onShowConversationContentLoading(): void {
    this.loading = true;
  }
  get user(): any {
    return store.state.user!.user;
  }

  get initials(): string {
    const firstName = this.user!.name!.firstName!.charAt(0).toUpperCase() || '';
    const lastName = this.user!.name!.lastName!.charAt(0).toUpperCase() || '';
    return `${firstName}${lastName}`;
  }

  showAvatar(index: number): boolean {
    return true;
    const actualMode = this.interactions[index].mode;
    return !(
      this.interactions[index - 1] &&
      this.interactions[index - 1].mode === actualMode
    );
  }

  getCompleteName(interaction: any): string {
    // if (interaction.interactionOrigin) {
    //   const { firstName, lastName } = this.$store.state.user.user.name;
    //   return `${firstName} ${lastName}`;
    // }
    if (!interaction || !interaction.agent) {
      return 'Mensaje automático';
    }
    if (
      interaction.agent != null &&
      interaction.agent.user != null &&
      interaction.agent.user.name != null
    ) {
      const { firstName, lastName } = interaction.agent.user.name;
      const formattedFirstName =
        firstName!.charAt(0).toUpperCase() + firstName!.slice(1);
      const formattedLastName =
        lastName!.charAt(0).toUpperCase() + lastName!.slice(1);

      return `${formattedFirstName || ''} ${formattedLastName || ''}`.trim();
    }
    return '';
  }

  getDateFormatted(index: number): string {
    const interactionTime = moment(this.interactions[index].createdAt);
    const today = moment().startOf('day');
    const yesterday = moment().startOf('day').subtract(1, 'day');

    let dateLabel;

    switch (true) {
      case interactionTime.isSameOrAfter(today):
        dateLabel = 'Hoy';
        break;
      case interactionTime.isSameOrAfter(yesterday):
        dateLabel = 'Ayer';
        break;
      default:
        dateLabel = interactionTime.format('DD/MM/YY');
        break;
    }

    return dateLabel;
  }
  newDate(index: number): boolean {
    if (index <= 0 || index >= this.interactions.length) {
      return false; // Invalid index, cannot compare with previous message
    }

    const currentMessage = this.interactions[index];
    const previousMessage = this.interactions[index - 1];

    const currentDate = moment(currentMessage!.createdAt);
    const previousDate = moment(previousMessage!.createdAt);

    return currentDate.startOf('day').isAfter(previousDate.startOf('day'));
  }

  setInteractionReply(interactionReply: Interaction, mySelf: boolean) {
    this.visible = true;
    this.$set(this.interact, 0, interactionReply);
  }

  setReply(interactionReply: Interaction, mySelf: boolean) {
    this.$root.$emit('sendReply', interactionReply, mySelf);
  }
  setConsumers(item: any) {
    const index = this.selected_consumers.findIndex(
      (el: any) => el._id === item._id,
    );

    if (index === -1) {
      this.selected_consumers.push(item);
    } else {
      this.selected_consumers.splice(index, 1);
    }
  }
  // check if the item is in the list
  // if there is a match, return true
  // if there is no match, return false
  inList(item: any) {
    const result = this.selected_consumers.some(
      (el: any) => el._id === item._id,
    );
    return result;
  }
  handleCheckboxChange(event: any, item: any) {}

  @Watch('visible')
  handleCloseSelected() {
    this.selected_consumers = [];
  }

  // check if the last interaction is mode output
  get checkLastInteraction() {
    if (this.interactions.length === 0) {
      return true; // No interactions, assume it's an input interaction
    }
    const lastInteraction = this.interactions[this.interactions.length - 1];
    return lastInteraction.mode !== 'OUTPUT';
  }

  // Mark as read
  // search in array of interactions, filter by type input and return the last one
  // if status is not read, mark as read
  async markAsRead() {
    let lastInput = this.interactions
      .filter(interaction => interaction.mode === 'INPUT')
      .pop();

    const response = await axios.put(
      `${environment.vueAppAPIOrchestrator}/conversation/${lastInput!._id}`,
      {
        status: 'read',
      },
    );
    if (response.status === 200) {
      // this.$root.$emit("markAsRead", this.currentConversation._id);
      store.commit(conversationActionsTypes.setNewInteractions(0));
    }
  }

  // action buttons
  takeConversation() {
    eventBus.$emit('takeConversation', this.currentConversation);
  }
  closeConversation() {
    eventBus.$emit('closeConversation', this.currentConversation);
  }
  transferConversation() {
    eventBus.$emit('transferConversation', this.currentConversation);
  }
}
