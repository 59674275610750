import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import { Interaction } from '@/app/store/modules/conversation/conversation.models';
import * as moment from 'moment';
import { BASE_URL_MANAGER } from '@/config';
import axios from 'axios';
import ResponseMessage from '../message-response/MessageResponse.vue';

@Component({
  name: 'message-file',
  components: { ResponseMessage },
})
export default class FileType extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;
  public fileUrl = '';

  get extentionIcon() {
    let icon = 'file-alt';
    let extention = this.message.mimetype;

    // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet
    // application/vnd.openxmlformats-officedocument.wordprocessingml.document
    // application/pdf
    // application/vnd.ms-excel
    // text/plain

    switch (extention) {
      case 'application/pdf':
        icon = 'file-pdf';
        break;
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        icon = 'file-word';
        break;
      case 'application/json':
        icon = 'file-code';
        break;
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
        icon = 'file-excel';
        break;
      case 'application/vnd.ms-excel':
        icon = 'file-excel';
        break;
    }
    return icon;
  }

  get iconStyle() {
    let style = { color: 'black', size: '50px' };
    let splittedName = this.message.filename.split('.');
    let extention = splittedName[splittedName.length - 1].toLowerCase();
    switch (extention) {
      case 'pdf':
        style.color = 'red';
        break;
      case 'docx':
        style.color = 'blue';
        break;
      case 'doc':
        style.color = 'blue';
        break;
      case 'xlsx':
        style.color = 'green';
        break;
      case 'csv':
        style.color = 'green';
        break;
    }
    return style;
  }

  get CreatedAt() {
    return moment.default(this.message.createdAt).format('LT');
  }

  get Mode() {
    return this.message.mode === 'OUTPUT';
  }

  get currentProject() {
    return this.$store.getters.project;
  }

  async download() {
    try {
      const response = await axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
        folderName: this.currentProject._id,
        fileName: this.message.keyS3!.split('/')[1],
      });
      //   rename file to original name
      const link = document.createElement('a');

      if (this.message.keyS3) link.download = this.message.filename;
      link.href = response.data.url;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';

      link.click();
      this.fileUrl = '';
    } catch (error) {
      console.log(error);
    }
  }

  getSubString(newString: string): string {
    if (newString.length > 80) {
      let extention = newString.substring(
        newString.length - 4,
        newString.length,
      );
      return newString.substring(0, 81) + '...' + extention;
    }
    return newString;
  }
}
