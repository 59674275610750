import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import {
  Interaction,
  InteractionOrigin,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import { BASE_URL_MANAGER, BASE_URL_ORCHESTATOR } from '@/config';
import Axios from 'axios';

@Component({
  name: 'message-response',
})
export default class MessageResponse extends VueWizard {
  @Prop() readonly interaction!: Interaction;

  public loading = false;
  public refData: any = '';
  public imageUrl: string = '';
  public visible = false;
  public rotate = 0;

  mounted() {
    this.referenceInteraction();
  }
  get isImage(): boolean {
    // if interaction mimetype is  null or undefined or empty return false
    if (!this.interaction.mimetype) return false;
    // if imageUrl is null or undefined or empty return false
    if (!this.imageUrl) return false;
    return (
      this.interaction.mimetype === 'image/jpeg' ||
      this.interaction.mimetype === 'image/png' ||
      this.interaction.mimetype === 'image/gif' ||
      this.interaction.mimetype === 'image/svg+xml' ||
      this.interaction.mimetype === 'image/webp' ||
      this.imageUrl.includes('.jpg') ||
      this.imageUrl.includes('.png') ||
      this.imageUrl.includes('.jpeg')
    );
  }

  getConsumer(item: Interaction): string {
    let consumer = item.consumer || '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }

  async referenceInteraction() {
    if (
      this.interaction.origin === InteractionOrigin.REF &&
      this.interaction.referenceId
    ) {
      const request = await Axios(
        `${BASE_URL_ORCHESTATOR}/conversation/interactions/${this.interaction.referenceId}`,
      );

      this.refData = request.data;
      this.imageUrl = request.data.fileurl;
    }
  }

  async dataUrlToFile(url?: string | any): Promise<string> {
    this.loading = true;
    let tempUrl = '';
    if (url) {
      await Axios.post(`${BASE_URL_MANAGER}/company/getfile`, {
        urlfile: url,
      }).then(response => {
        tempUrl = response.data.file;
        this.loading = false;
      });
    }
    return tempUrl;
  }

  changeRotate() {
    if (this.rotate < 3) {
      this.rotate++;
    } else {
      this.rotate = 0;
    }
  }

  changeRotateMin() {
    if (this.rotate === 0) {
      this.rotate = 3;
    } else {
      this.rotate--;
    }
  }
}
