var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.interaction.origin === 'REF' && _vm.refData)?_c('div',{staticClass:"data-reply-container"},[_c('div',{staticClass:"data-reply-content"},[_c('div',{staticClass:"btn data-reply-message"},[_c('span',{class:_vm.refData.mode === 'INPUT' ? 'bg-color-info' : 'bg-color-agent'}),_c('div',{staticClass:"data-reply-content-text"},[_c('div',{staticClass:"data-reply-text"},[_c('div',{staticClass:"data-reply-responsable"},[_c('span',{style:(_vm.refData.mode === 'INPUT' ? 'color: #0c90c0' : 'color: #ee985f')},[_vm._v(_vm._s(_vm.refData.mode === "INPUT" ? `+ ${_vm.getConsumer(_vm.interaction)}` : "Tú"))])]),_c('div',{staticClass:"data-reply-description"},[(_vm.refData.text.toString() && !_vm.refData.fileurl)?_c('span',[_vm._v(_vm._s(_vm.refData.text.toString()))]):(!_vm.refData.text.toString() &&
              _vm.refData.fileurl &&
              _vm.refData.mimetype === 'application/pdf'
            )?_c('span',{staticClass:"documentText"},[_c('font-awesome-icon',{attrs:{"icon":"paperclip"}}),_vm._v(" "+_vm._s(_vm.refData.filename.toString())+" ")],1):_vm._e(),((_vm.refData.fileurl && _vm.refData.mimetype === 'image/png') ||
              _vm.refData.mimetype === 'image/webp' ||
              _vm.refData.mimetype === 'image/jpeg' ||
              _vm.refData.mimetype === 'image/gif'
            )?_c('div',{staticClass:"reply-image-container"},[_c('div',{staticClass:"reply-image-content"},[_c('div',{class:_vm.loading ? 'loading' : 'reply-image-item'},[(_vm.loading)?_c('div',{staticClass:"d-flex align-items-center justify-content-center"},[_c('span',{staticClass:"text-primary"},[_c('font-awesome-icon',{attrs:{"icon":"circle-notch","spin":"","size":"lg"}})],1)]):_vm._e(),_c('a',{on:{"click":function($event){_vm.visible = true}}},[_c('img',{staticClass:"message-image",attrs:{"src":_vm.imageUrl,"width":"250","alt":"preview"}})])])])]):_vm._e(),(_vm.refData.mimetype === 'audio/ogg; codecs=opus')?_c('div',[_c('audio',{attrs:{"controls":"","src":_vm.imageUrl}})]):_vm._e(),_c('a-modal',{staticClass:"overflow-hidden",attrs:{"title":"Previsualizador","footer":null,"width":'50%'},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"btn-group btn-group-sm position-absolute"},[_c('a',{staticClass:"btn btn-danger btn-sm",attrs:{"download":"image","href":_vm.imageUrl,"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":"download"}})],1),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeRotate()}}},[_c('font-awesome-icon',{attrs:{"icon":"redo"}})],1),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeRotateMin()}}},[_c('font-awesome-icon',{attrs:{"icon":"undo"}})],1)]),_c('div',{staticClass:"text-center overflow-auto"},[(_vm.isImage)?_c('div',[_c('img',{staticClass:"border-primary border full-image",class:{
                    rotate90: _vm.rotate === 1,
                    rotate180: _vm.rotate === 2,
                    rotate270: _vm.rotate === 3,
                  },attrs:{"src":_vm.imageUrl,"alt":"preview"}})]):_c('div',[_c('video',{class:{
                    rotate90: _vm.rotate === 1,
                    rotate180: _vm.rotate === 2,
                    rotate270: _vm.rotate === 3,
                  },attrs:{"src":_vm.imageUrl,"controls":"","alt":"preview","title":"Video"}})])])])],1)])])])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }