var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{class:{ 'text-right': _vm.Mode }},[(_vm.loading)?_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"text-primary"},[_c('font-awesome-icon',{attrs:{"icon":"circle-notch","spin":"","size":"lg"}})],1)]):_c('div',[_c('MessageResponse',{attrs:{"interaction":_vm.message}}),_c('div',{staticClass:"image-container"},[(_vm.isImage)?_c('div',[_c('a',{on:{"click":function($event){_vm.visible = true}}},[_c('img',{staticClass:"message-image",attrs:{"src":_vm.fileUrl,"width":"250","alt":"preview"}})])]):_c('div',[_c('a',{on:{"click":function($event){_vm.visible = true}}},[_c('video',{attrs:{"src":_vm.fileUrl,"width":"250","autoplay":"","muted":"","loop":""},domProps:{"muted":true}})])]),_c('a-modal',{staticClass:"overflow-hidden",attrs:{"title":"Previsualizador","footer":null,"width":'50%'},model:{value:(_vm.visible),callback:function ($$v) {_vm.visible=$$v},expression:"visible"}},[_c('div',{staticClass:"btn-group btn-group-sm position-absolute"},[_c('a',{staticClass:"btn btn-danger btn-sm",attrs:{"download":"image","href":_vm.fileUrl,"target":"_blank"}},[_c('font-awesome-icon',{attrs:{"icon":"download"}})],1),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeRotate()}}},[_c('font-awesome-icon',{attrs:{"icon":"redo"}})],1),_c('button',{staticClass:"btn btn-info",attrs:{"type":"button"},on:{"click":function($event){return _vm.changeRotateMin()}}},[_c('font-awesome-icon',{attrs:{"icon":"undo"}})],1)]),_c('div',{staticClass:"text-center overflow-auto"},[(_vm.isImage)?_c('div',[_c('img',{staticClass:"border-primary border full-image",class:{
              rotate90: _vm.rotate === 1,
              rotate180: _vm.rotate === 2,
              rotate270: _vm.rotate === 3,
            },attrs:{"src":_vm.fileUrl}})]):_c('div',[_c('video',{class:{
              rotate90: _vm.rotate === 1,
              rotate180: _vm.rotate === 2,
              rotate270: _vm.rotate === 3,
            },attrs:{"src":_vm.fileUrl,"controls":""}})])])])],1)],1),_c('small',{staticClass:"badge d-flex justify-content-between align-items-center"},[(_vm.message.delivered !== null && _vm.message.delivered !== undefined)?_c('div',[(_vm.message.delivered)?_c('small',{staticClass:"text-primary"},[_c('font-awesome-icon',{attrs:{"icon":"check-double"}})],1):_vm._e()]):_vm._e(),_c('span',{staticClass:"image-text"},[_vm._v(_vm._s(_vm.getSubString(_vm.message)))]),_c('i',{staticClass:"badge"},[_vm._v(_vm._s(_vm.CreatedAt))])])])
}
var staticRenderFns = []

export { render, staticRenderFns }