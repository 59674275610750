import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import {
  Interaction,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import * as moment from 'moment';
import MessageResponse from '../message-response/MessageResponse.vue';
@Component({ name: 'message-location', components: { MessageResponse } })
export default class MessageLocation extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;
  @Prop() selfUser!: boolean;

  public mapOptions = {
    center: { lat: parseFloat(this.latitude), lng: parseFloat(this.longitude) },
    zoom: 17,
    disableDefaultUI: true,
    gestureHandling: 'none',
    keyboardShortcuts: false,
    //
  };
  mounted() {
    console.log('MessageLocation mounted');
    console.log('message', this.message);

  }
  public loading = false;
  public link = `https://www.google.com/maps/search/?api=1&query=${this.latitude},${this.longitude}`;
  get Text(): string {
    return 'Ver ubicación';
  }
  get longitude(): string {
    if (
      this.message.fullMessage !== undefined &&
      this.message.fullMessage.longitude !== undefined
    )
      return this.message.fullMessage.longitude;
    return '';
  }
  get latitude(): string {
    if (
      this.message.fullMessage !== undefined &&
      this.message.fullMessage.latitude !== undefined
    )
      return this.message.fullMessage.latitude;
    return '';
  }
  get CreatedAt(): string {
    return moment.default(this.message.createdAt).format('LT');
  }
  get Mode(): boolean {
    return this.message.mode === 'OUTPUT';
  }
  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }
}