import { Component, Prop, Watch } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import {
  Interaction,
  MessageType,
} from '@/app/store/modules/conversation/conversation.models';
import Message from '../../messages/message-text/MessageText.vue';
import ImageMessage from '../../messages/message-image/MessageImage.vue';
import FileType from '../../messages/message-file/MessageFile.vue';
import AudioType from '../../messages/message-audio/MessageAudio.vue';
import Location from '../../messages/message-location/MessageLocation.vue';
@Component({ name: 'conversation-message' })
export default class ConversationMessage extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly project!: any;
  get currentMessage(): any {
    switch (this.message.type) {
      case MessageType.MESSAGE:
        return Message;
      case MessageType.IMAGE:
        return ImageMessage;
      case MessageType.FILE:
        return FileType;
      case MessageType.AUDIO:
        return AudioType;
      case MessageType.VIDEO:
        return ImageMessage;
      case MessageType.LOCATION:
        return Location;
      default:
        return;
    }
  }
}
