import { Component, Prop } from 'vue-property-decorator';
import { VueWizard } from '@/vue-wizard';
import {
  Interaction,
  Conversation,
} from '@/app/store/modules/conversation/conversation.models';
import * as moment from 'moment';
import anchorme from 'anchorme';
import MessageResponse from '../message-response/MessageResponse.vue';

@Component({ name: 'message-text', components: { MessageResponse } })
export default class MessageText extends VueWizard {
  @Prop() readonly message!: Interaction;
  @Prop() readonly createdAt!: Date;
  @Prop() readonly mode!: string;
  @Prop() selfUser!: boolean;

  public loading: boolean = false;
  get Text(): string {
    let formatedText = [];
    let splittedText = this.message.text.join('\n').split(' ');
    splittedText.forEach((word: any) => {
      formatedText.push(
        anchorme({
          input: word,
          options: {
            attributes: {
              target: word,
              class: 'font-weight-bold',
            },
            truncate: 48,
            middleTruncation: true,
          },
        }),
      );
    });
    return this.message.text.join('\n');
  }

  get CreatedAt(): string {
    return moment.default(this.message.createdAt).format('LT');
  }

  get Mode(): boolean {
    return this.message.mode === 'OUTPUT';
  }

  getConsumer(item: Conversation): string {
    let consumer = item.currentConsumer
      ? item.currentConsumer
      : item.consumer
      ? item.consumer
      : '';
    if (consumer.includes('@')) consumer = consumer.split('@')[0];
    return consumer;
  }
}
