import { VueWizard } from "@/vue-wizard";
import Component from "vue-class-component";

import { Prop } from "vue-property-decorator";

@Component({
  name: "custom-button",
})

// recive el nombre a mostrar del boton
// recive la funcion a ejecutar
// recive el color del boton
// recive el icono a mostrar
export default class CustomButton extends VueWizard {
  @Prop() readonly name!: string;
  @Prop() readonly function!: any;
  @Prop() readonly type!: string;

  // ejecuta la funcion que recibe por parametro
  executeFunction() {
    this.function();
  }

  parseClass() {
    if (this.type == "take") {
      return "btn-take";
    } else if (this.type == "transfer") {
      return "btn-transfer";
    } else if (this.type == "finish") {
      return "btn-finish";
    } else if (this.type == "re-open") {
      return "btn-re-open";
    }
    return "btn-default";
  }

  parseSVG() {
    if (this.type == "take") {
      return "svg-icon take";
    } else if (this.type == "transfer") {
      return "svg-icon transfer";
    } else if (this.type == "finish") {
      return "svg-icon finish";
    } else if (this.type == "re-open") {
      return "svg-icon re-open";
    }
    return "svg-icon take";
  }
}
